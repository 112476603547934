:root {
    --mrc-blue: #2b336b;
    --mrc-light-blue: #444fa3;
    --borders: 1px solid rgb(215, 215, 215);
    --shadow-black: rgba(0, 0, 0, 0.222); 
  }

.container{
    height: 10%;
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    border-bottom: var(--borders);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.061);
}

.container h2{
    margin-left: 1cm;
}

.icon{
    margin-right: 0.3cm;
}

.backLink{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 7%;
    border-radius: 9px 0 0 0;
    transition: background-color 0.2s;
    border-right: var(--borders);
}
.backLink:hover{
    background-color: var(--mrc-light-blue);
    color: white;

}