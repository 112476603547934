:root {
    --mrc-blue: #2b336b;
    --mrc-light-blue: #444fa3;
    --borders: 1px solid rgb(215, 215, 215);
    --shadow-black: rgba(0, 0, 0, 0.222); 
}

.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 1cm;
    height: 80%;
    overflow-y: auto;
}

.addFileIcon {
    color: green;
}
.addFileButton{
    margin-top: 0.4cm;
    font-weight: 700;
    font-size: 12pt;
    width: 90%;
    background-color: white;
    min-height: 2cm;
    margin-bottom: 0.5cm;
    box-shadow: 0 0 15px var(--shadow-black);
    border-radius: 5px;
    border: none;
    transition: all 0.2s;
}
.addFileButton:hover{
    color: white;
    background-color: rgb(4, 104, 4);
    transition: all 0.2s;
}