.container{
    position: absolute;
    width: 100dvw;
    height: 100dvh;
    display: flex;
    
    backdrop-filter: blur(8px);
    align-items: center;
    justify-content: center;
    animation: show 0.2s;
}
.main{
    display: flex;
    background-color: white;
    flex-direction: column;
    width: 50%;
    border-radius: 9px;
    box-shadow: 0 0 15px var(--shadow-black);
}
.form{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.form h2{
    margin: 0.1cm;
    width: 100%;
    text-align: center;
}
.form h4{
    margin-top: 0.2cm;
}
.form button{
    margin-top: 0.3cm;
    margin-bottom: 0.3cm;
    width: 60%;
    border-radius: 5px;
    color: white;
    padding: 0 0.5cm 0 0.5cm;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(1, 102, 1);
    height: 1cm;
}

.form button:hover{
    background-color: rgb(0, 66, 0);
}

.formInput{
    box-shadow: 0 0px 15px rgba(0, 0, 0, 0.141);
    border: none;
    height: 1cm;
    outline: none;
    width: 5cm;
    font-size: 12pt;
    border-bottom: 1px solid var(--mrc-light-blue);
    background-color: white;
    padding-left: 0.2cm;
}