:root {
    --mrc-blue: #2b336b;
    --mrc-light-blue: #444fa3;
    --borders: 1px solid rgb(215, 215, 215);
    --shadow-black: rgba(0, 0, 0, 0.222); 
  }


@keyframes show {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
    
}
.container{
    position: absolute;
    width: 100dvw;
    height: 100dvh;
    display: flex;
    
    backdrop-filter: blur(8px);
    align-items: center;
    justify-content: center;
    animation: show 0.2s;
}
.main{
    display: flex;
    background-color: white;
    flex-direction: column;
    width: 95vw;
    height: 90vh;
    border-radius: 9px;
    box-shadow: 0 0 15px var(--shadow-black);
}

.buttonContainer{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
}

.tableButton{
   font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1cm;
    padding: 0 0.5cm 0 0.5cm;
    border-radius: 4px;
    font-size: 0.8em;
    margin-left: 2cm;
    outline: none;
    border: none;
    color: white;
}

.buttonContainer button .icon{
    margin-right: 0.2cm;
}

.redButton{
    background-color: rgb(197, 0, 0);
  }
.redButton:hover{
    background-color: rgb(113, 0, 0);
  }
  
  .yellowButton{
    background-color: rgb(154, 149, 2);
  }
  .yellowButton:hover{
    background-color: rgb(83, 82, 1);
  }

  .greenButton{
    background-color: rgb(2, 154, 32);
  }
  .greenButton:hover{
    background-color: rgb(1, 83, 5);
  }
  
  .searchContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .searchContainer input {
    margin-left: 0.2cm;
    margin: 0.2cm;
    min-height: 1cm;
  }

.screen{
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(8px);
    width: 100vw;
    height: 100vh;
    z-index: 999;
}
.loader {
    width: 48px;
    height: 48px;
    display: block;
    margin: 15px auto;
    position: relative;
    color: #ccc;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  .loader::after,
  .loader::before {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    width: 24px;
    height: 24px;
    top: 50%;
    left: 50%;
    transform: scale(0.5) translate(0, 0);
    background-color: #ccc;
    border-radius: 50%;
    animation: animloader 1s infinite ease-in-out;
  }
  .loader::before {
    background-color: #000;
    transform: scale(0.5) translate(-48px, -48px);
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 
  @keyframes animloader {
    50% {
      transform: scale(1) translate(-50%, -50%);
    }
  }

  .pagButtons{
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.183);
    padding-top: 0.3cm;
  }

  .pagButtons button{
        width: 2cm;
        height: 1cm;
        background-color: var(--mrc-light-blue);
        color: white;
        margin: 0.2cm;
        outline: none;
        border: none;
        border-radius: 5px;
  }
  
  .pagButtons button:hover{
    background-color: var(--mrc-blue);
}
.pagButtons button:disabled{
    background-color:   rgb(157, 157, 219);
}



/*table*/

.table{
    width: 100%;
    gap: 0;
    border-collapse: collapse;
}

.table thead{
    background-color: var(--mrc-light-blue);
    color:white;
    height: 1.5cm;
    font-size: 1em;
}
.table tbody tr:nth-child(odd){
    background-color: rgb(223, 223, 223);
}

.table tr, .table th{
    border: none;
    outline: none;
    max-height: 1cm;
}
.table td p{
  white-space: nowrap;
  overflow: hidden;
  font-size: 0.7em;
}