.container{
    position: absolute;
    width: 100dvw;
    height: 100dvh;
    display: flex;
    
    backdrop-filter: blur(8px);
    align-items: center;
    justify-content: center;
    animation: show 0.2s;
}
.main{
    display: flex;
    background-color: white;
    flex-direction: column;
    width: 95vw;
    height: 90vh;
    border-radius: 9px;
    box-shadow: 0 0 15px var(--shadow-black);
}

.topTableContainer{
    width: 100%;
    border-bottom: 1px solid #bababa;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 25%;
}
.topTableRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0.2cm;
}
.topTableContainer table{
    border-collapse: collapse;
    border-spacing: 1px;
    text-align: center;
    border: 1px solid #dededf;
    margin: 0.1cm;
}
.topTableContainer thead{
    font-weight: bold;
}

.topTableContainer th{
        border: 1px solid #dededf;
        background-color: #ffffff;
        color: #000000;
        padding: 5px;
}
.topTableContainer td{
    border: 1px solid #dededf;
    padding: 5px;
}
.carouselContainer{
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.enviar{
    height: 1cm;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(1, 102, 1);
    color: white;
    padding: 0 0.5cm 0 0.5cm;
    outline: none;
    border: none;
    margin-left: 1cm;
}
.enviar:hover{
    background-color: rgb(0, 66, 0);
}

.reenviar{
    height: 1cm;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(152, 115, 3);
    color: white;
    padding: 0 0.5cm 0 0.5cm;
    outline: none;
    border: none;
    margin-left: 1cm;
}
.reenviar:hover{
    background-color: rgb(66, 44, 0);
}


.screen{
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(8px);
    width: 100vw;
    height: 100vh;
    z-index: 999;
}
.loader {
    width: 48px;
    height: 48px;
    display: block;
    margin: 15px auto;
    position: relative;
    color: #ccc;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  .loader::after,
  .loader::before {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    width: 24px;
    height: 24px;
    top: 50%;
    left: 50%;
    transform: scale(0.5) translate(0, 0);
    background-color: #ccc;
    border-radius: 50%;
    animation: animloader 1s infinite ease-in-out;
  }
  .loader::before {
    background-color: #000;
    transform: scale(0.5) translate(-48px, -48px);
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 
  @keyframes animloader {
    50% {
      transform: scale(1) translate(-50%, -50%);
    }
  }
