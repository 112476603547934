.container {
    position: absolute;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.143);
    backdrop-filter: blur(9px);
    top: 0;
    left: 0;
}

.container.show{
    display: flex;
}
.container.hide{
    display: none;
}

.modal{
    width: 50%;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 9px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.356);
}
.close{
    width: 97%;
    display: flex;
    padding: 0.2cm;
    padding-top: 0.4cm;
    font-size: 20pt;
    justify-content: end;
    color: rgb(155, 155, 155);
    height: 1cm;
}
.closeButton:hover{
    color: rgb(0, 0, 0) !important;
}
.text{
    font-size: 20pt;
    font-weight: 600;
    padding-top: 1cm;
    min-height: 2cm;
    margin-bottom: 1cm;
    margin: 0.5cm;
    margin-bottom: 1cm;
}
.buttons{
    width: 95%;
    display: flex;
    justify-content: end;
}
.buttons button{
    min-width: 1cm;
    height: 1cm;
    border-radius: 5px;
    outline: none;
    border: none;
    margin: 0.2cm;
    color: white;
    font-size: 12pt;
}
.confirm{
    background-color: rgb(0, 100, 0);
}
.confirm:hover{
    background-color: rgb(0, 65, 0);
}
.confirm:disabled{
    background-color: rgb(205, 255, 205);
}
.cancel{
    background-color: rgb(192, 0, 0);
}
.cancel:hover{
    background-color: rgb(80, 0, 0);
}
.cancel:disabled{
    background-color: rgb(255, 163, 163);
}

.toastMessage{
    color: black;
    font-weight: 600;
}