:root {
    --mrc-blue: #2b336b;
    --mrc-light-blue: #444fa3;
    --borders: 1px solid rgb(215, 215, 215);
    --shadow-black: rgba(0, 0, 0, 0.222); 
  }


@keyframes show {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
    
}
.container{
    position: absolute;
    width: 100dvw;
    height: 100dvh;
    display: flex;
    
    backdrop-filter: blur(8px);
    align-items: center;
    justify-content: center;
    animation: show 0.2s;
}
.main{
    display: flex;
    background-color: white;
    flex-direction: column;
    width: 95vw;
    height: 90vh;
    border-radius: 9px;
    box-shadow: 0 0 15px var(--shadow-black);
}