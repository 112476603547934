:root {
    --mrc-blue: #2b336b;
    --mrc-light-blue: #444fa3;
    --borders: 1px solid rgb(215, 215, 215);
    --shadow-black: rgba(0, 0, 0, 0.222); 
  }

.container{
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.logo{
    margin: 0.5cm;
}
.hr{
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.122);
}
.form{
    width: 30%;
    min-width: 15cm;
    background-color: white;
    padding: 0.3cm;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.246);
    border-radius: 9px;
}
.form input[type=text], form input[type=password]{
    width: 60%;
    height: 1cm;
    border: none;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.246);
    border-bottom: 1px solid var(--mrc-light-blue);
    margin: 0.2cm;

}
.form input[type=submit]{
    width: 60%;
    height: 1.3cm;
    border: none;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.246);
    margin: 0.2cm;
    margin-bottom: 1cm;
    background-color: #444fa3;
    color: white;
    font-weight: 600;
}
.form input[type=submit]:hover{
    background-color: var(--mrc-blue);
}
.branding{
    width: 30%;
    margin-top: 5%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.branding img{
    width: 33%;
}