.App {
  text-align: center;
}

.mainContainer{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
}
.buttonsContainer{
  padding: 0.3cm;
  background-color: white;
  min-height: 10cm;
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.37);
}
.buttonsContainer .link{
  width: 13cm;
  height: 2cm;
  text-decoration: none;
  font-weight: 600;
  font-size: 24pt;
  background-color: #444fa3;
  margin-bottom: 0.5cm;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 5px;
}
.buttonsContainer .link:hover{
  background-color: #2b336b;
}
.buttonsContainer .logo{
  margin-bottom: 0.5cm;
}
.buttonsContainer .salirButton{
  border: none;
  outline: none;
  background-color: rgb(190, 56, 56);
}
.buttonsContainer .salirButton:hover{
  background-color: rgb(93, 29, 29);
}
.buttonsContainer  .icon{
  margin-right: 0.4cm;
}