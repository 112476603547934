:root {
    --mrc-blue: #2b336b;
    --mrc-light-blue: #444fa3;
    --borders: 1px solid rgb(215, 215, 215);
    --shadow-black: rgba(0, 0, 0, 0.222); 
  }
.formLine{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-height: 1.5cm;
}
.container {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-left: calc(10% - 0.5cm);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.061);
    border-bottom: var(--borders);
    padding-top: 0.2cm;
    padding-bottom: 0.2cm;
}
.container h4{
    margin-right: 0.1cm;
    margin-left: 0.5cm;
}
.input{
    box-shadow: 0 0px 15px rgba(0, 0, 0, 0.141);
    border: none;
    height: 0.8cm;
    outline: none;
    width: 5cm;
    font-size: 12pt;
    border-bottom: 1px solid var(--mrc-light-blue);
    background-color: white;
    padding-left: 0.2cm;
}
.inputComentario{
    box-shadow: 0 0px 15px rgba(0, 0, 0, 0.141);
    border: none;
    height: 1cm;
    outline: none;
    width: 60%;
    font-size: 12pt;
    border-bottom: 1px solid var(--mrc-light-blue);
    background-color: transparent;
    padding-left: 0.2cm;
}
.icon{
    font-size: 25px;
    margin-right: 0.2cm;
}
.enviar{
    height: 1cm;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(1, 102, 1);
    color: white;
    padding: 0 0.5cm 0 0.5cm;
    outline: none;
    border: none;
    margin-left: 1cm;
}
.enviar:hover{
    background-color: rgb(0, 66, 0);
}