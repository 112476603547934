:root {
    --mrc-blue: #2b336b;
    --mrc-light-blue: #444fa3;
    --borders: 1px solid rgb(215, 215, 215);
    --shadow-black: rgba(0, 0, 0, 0.222); 
  }

.container{
    width: 90%;
    min-height: 1.7cm;
    background-color: white;
    box-shadow: 0 0 15px var(--shadow-black);
    border-radius: 5px;
    margin-top: 0.4cm;
    display: flex;
    align-items: center;
    justify-content: center;
}
.deleteButton{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(197, 0, 0);
  height: 1cm;
  padding: 0 0.5cm 0 0.5cm;
  border-radius: 4px;
  font-size: 15pt;
  margin-left: 2cm;
  outline: none;
  border: none;
  color: white;
}
.deleteButton:hover{
  background-color: rgb(113, 0, 0);
}

.fileSelector::file-selector-button{
  background-color: white;
  border-radius: 5px;
  outline: none;
  border: none;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.347);
  height: 1cm;
  margin-right: 0.3cm;
  min-width: 2cm;
  border: 1px solid rgba(0, 0, 0, 0.425);
  font-weight: 600;
}
.fileSelector::file-selector-button:hover{
  background-color: rgb(173, 173, 173);
}
.fileSelector{
  margin-right: 1cm;
  width: 10cm;
}

.uploaded::file-selector-button{
  background-color: green !important;
  color: white;
}

.textarea{
  border: none;
  border-radius: 0;
  border-bottom: 1px solid var(--mrc-blue);
  box-shadow: 0 0 15px var(--shadow-black);
  font-size: 15pt;
}
.label{
  margin-right: 0.2cm;
  font-weight: 600;
}